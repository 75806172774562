.with-back-button-container {
  position: absolute;
  width: 100vw;
  top: 0;
  height: var(--top-bar-height);
}

.back-icon {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 38px;
  height: 38px;
  color: white;
  z-index: 2000;
}

@media only screen and (min-width: 1080px) {
  .with-back-button-container {
    width: 100%;
  }
  .back-icon {
    display: none;
  }
}
