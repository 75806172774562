#event-title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 36.4px;
  font-family: var(--font-plex-sans);
}
@media only screen and (max-width: 769px) {
  #event-title {
    font-size: 2.5rem;
  }
}
