.bottom-nav {
	display: flex;
	justify-content: space-between;
	height: var(--bottom-nav-height);
	background-color: var(--matte-black);
	position: absolute;
	bottom: 0px;
	width: 100%;
	z-index: 3;
}

.all-events-button {
	position: absolute;
	top: 20px;
	right: 80px;
	display: none;
}

@media only screen and (min-width: 1080px) {
	.bottom-nav {
		width: calc(var(--desktop-width) + 100px);
		position: absolute;
		height: 70px;
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: calc(var(--desktop-margin) - 50px);
		padding-right: calc(var(--desktop-margin) - 50px);
		background-color: var(--matte-black);
		top: 0px;
		bottom: auto;
	}

	.desktop-match-text {
		font-family: var(--font-plex-sans);
		position: absolute;
		left: 0px;
		display: block;
		line-height: 70px;
		margin-left: 25px;
		color: white;
	}

	.all-events-button {
		display: block;
	}
}

@media only screen and (min-width: 1250px) {
	.bottom-nav {
		width: calc(var(--wide-desktop-width) + 150px);
		padding-left: calc(var(--wide-desktop-margin) - 75px);
		padding-right: calc(var(--wide-desktop-margin) - 75px);
	}
}

@media only screen and (min-width: 1600px) {
	.bottom-nav {
		width: calc(var(--ultrawide-desktop-width) + 150px);
		padding-left: calc(var(--ultrawide-desktop-margin) - 75px);
		padding-right: calc(var(--ultrawide-desktop-margin) - 75px);
	}
}
