html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

select {
    padding: 5px;
    background-color: var(--dark);
    color: white;
    border-radius: var(--border-radius);
    font-size: 16px;
    font-family: "IBM Plex Sans", sans-serif;
}

input[type="checkbox"] {
    accent-color: var(--dark);
    height: 16px;
    width: 16px;
}

option {
    background-color: red;
}

html,
body {
    overflow: auto;
    height: 100%;
    margin: 0px;
    width: 100%;
    overflow-x: hidden;
    scrollbar-width: none;
    line-height: 1;
    background-color: var(--deepest-dark);
}

html {
    font-size: 62.5%;

    @media (min-width: 20rem) {
        /* 20*16px = 320px */
        /* 200% of 16px = 32px */
    }

    @media (min-width: 30em) {
        /* 30*16px = 480px */
    }
}

:root {
    /*layout */
    --desktop-margin: 25%;
    --desktop-width: 50%;
    --wide-desktop-margin: 30%;
    --wide-desktop-width: 40%;
    --ultrawide-desktop-margin: 35%;
    --ultrawide-desktop-width: 30%;
    --bottom-nav-height: 65px;
    --top-bar-height: 65px;
    --top-bar-height-desktop: 70px;

    /* component layouts*/
    --general-card-height: 90px;
    --flat-general-card-height: 60px;
    --border-radius: 3px;
    --small-spacing: 4px;
    --medium-spacing: 8px;
    --large-sapcing: 12px;
    --section-spacing: 40px;

    /*colors*/
    --deepest-dark: rgba(0, 15, 17, 255);
    --matte-black: #121921;
    --dark: #1b292f;
    --gray: rgba(255, 255, 255, 0.125);
    --light-gray: rgba(255, 255, 255, 0.5);
    --turquoise: #016b7b;
    --dark-blue: #00343c;
    --bright-blue: #65f5e6;
    --red: rgba(137, 14, 14, 0.75);
    --bright-red: rgba(191, 23, 23, 1);
    --faint-border-color: rgba(255, 255, 255, 0.5);

    /* fonts*/
    --font-plex-mono: "IBM Plex Mono", monospace;
    --font-plex-sans: "IBM Plex Sans", sans-serif;
    --font-plex-condensed: "IBM Plex Sans Condensed", sans-serif;
}

body {
    font-size: 1.4rem;
}

body:first-child {
    height: 100vh;
}

h1 {
    font-size: 2.25rem;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
}

h2 {
    font-size: 2rem;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
}

h3 {
    font-size: 1.85rem;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
    letter-spacing: 0.02rem;
}

h4 {
    font-size: 1.6rem;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
}

ul {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: var(--font-plex-condensed);
}

li {
    font-size: 1.5rem;
    color: white;
}

p,
a,
label {
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: var(--font-plex-sans);
    color: white;
}

button {
    font-size: 1.75rem;
}

span {
    font-size: 1.5rem;
}

small {
    font-size: 1.5rem;
    font-family: var(--font-plex-sans);
    color: white;
}

@media only screen and (max-width: 769px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    p,
    li,
    label {
        font-size: 1.3rem;
    }

    small {
        font-size: 1.3rem;
    }

    button {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1080px) {
    body {
        background-image: url("images/background-4.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

main {
    scroll-behavior: smooth;
    scrollbarwidth: none;
    overflow-x: hidden;
    flex: 3;
    padding-top: calc(var(--top-bar-height) + 16px);
    padding-bottom: calc(var(--bottom-nav-height) + 16px);
}

@media only screen and (min-width: 1080px) {
    main {
        padding-top: calc(var(--top-bar-height-desktop) + 24px);
        margin-left: var(--desktop-margin);
        margin-right: var(--desktop-margin);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1250px) {
    main {
        margin-left: var(--wide-desktop-margin);
        margin-right: var(--wide-desktop-margin);
    }
}

@media only screen and (min-width: 1600px) {
    main {
        margin-left: var(--ultrawide-desktop-margin);
        margin-right: var(--ultrawide-desktop-margin);
    }
}

img {
    z-index: 0;
}

textarea {
    font-family: var(--font-plex-sans);
    font-size: 1.8rem;
    width: 100%;
    border: 0.5px solid var(--dark-blue);
    color: var(--dark-blue);
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px;
}

input {
    display: block;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: black;
    color: white;
    border: 0.5px solid white;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-plex-sans);
    text-align: center;
}

.professio-match-logo {
    display: none;
}

.MuiChip-label {
    height: 23px;
    line-height: 22px;
}

.fade {
    overflow: scroll;
    overflow-x: hidden;
    position: absolute;
    width: 100vw;

    animation-name: example;
    animation-duration: 0.5s;
}

.sign-out-text-navigation {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    display: none;
}

.sign-out-text-top-bar {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
}

.sign-out-text-navigation:hover {
    color: var(--bright-blue);
}

.meeting-message-text {
    color: var(--bright-blue);
    white-space: pre-wrap;
    font-weight: 600;
}

.text-wall-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

@media only screen and (min-width: 1080px) {
    .sign-out-text-navigation {
        display: block;
        top: 20px;
        right: 25px;
    }

    .professio-match-logo {
        display: block;
        height: 32px;
        position: absolute;
        left: 20px;
        top: 20px;
    }

    .dynamic-logo {
        display: block;
        position: absolute;
        left: 20px;
        top: 20px;
        max-height: 40px;
    }

    .text-wall-container {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }
}

@keyframes example {
    from {
        opacity: 1;
        top: var(--top-bar-height);
    }
    to {
        opacity: 0.5;
        top: calc(var(--top-bar-height) - 100px);
    }
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0px;
}

select:disabled {
    opacity: 0.6;
    color: gray;
}

.mono {
    font-family: var(--font-plex-mono);
}

.sans {
    font-family: var(--font-plex-sans);
}

.condensed {
    font-family: var(--font-plex-condensed);
}

.pointer {
    cursor: pointer;
}

.absolute {
    position: absolute;
}

.side-spacing {
    padding-left: 12px;
    padding-right: 12;
}

.app-padding {
    padding-left: var(--large-sapcing);
    padding-right: var(--large-sapcing);
}

.app-border-radius {
    border-radius: 0px;
}

@media only screen and (min-width: 1080px) {
    .app-border-radius {
        border-radius: var(--border-radius);
    }
}

.app-margin {
    margin-top: var(--large-sapcing);
    margin-bottom: var(--large-sapcing);
}

@media only screen and (min-width: 1080px) {
    .app-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.img-border-radius {
    border-radius: 0.25em;
}

.center-70 {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.center-75 {
    width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
}

.center-80 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.dot {
    height: 3px;
    width: 3px;
    border-radius: 2px;
    background-color: white;
    display: inline-block;
    position: absolute;
    top: calc(50% - 1px);
}

.faint-border-bottom {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
}

.dark-border-bottom {
    border-bottom: 0.5px solid var(--deepest-dark);
}

.bright-border-bottom {
    border-bottom: 0.5px solid;
}

.margin-auto {
    margin: auto;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.white {
    color: white;
}

.background-white {
    background-color: white;
}

.black {
    color: black;
}

.background-black {
    background-color: black;
}

.blue {
    color: var(--turquoise);
}

.background-blue {
    background-color: var(--turquoise);
}

.background-red {
    background-color: var(--red);
}

.bright-red {
    color: var(--bright-red);
}

.bright-blue {
    color: var(--bright-blue);
}

.background-bright-blue {
    background-color: var(--bright-blue);
}

.background-dark {
    background-color: var(--dark);
}

.background-matte-black {
    background-color: var(--matte-black);
}

.center {
    text-align: center;
}

.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    resize: vertical;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.view-height {
    height: calc(100vh - var(--top-bar-height));
}

.view-height-no-top-bar {
    height: calc(100vh);
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.small-spacing {
    margin-bottom: var(--small-spacing);
}

.medium-spacing {
    margin-bottom: var(--small-spacing);
}

.large-spacing {
    margin-bottom: var(--large-spacing);
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.left {
    float: left;
}

.right {
    float: right;
}

.relative {
    position: relative;
}

.opacity-05 {
    opacity: 0.3;
}

/
/
/
/
Automatic margins between child elements
.child-margins-x-4 > * + * {
    margin-left: 4px;
}

.child-margins-x-8 > * + * {
    margin-left: 8px;
}

.child-margins-x-12 > * + * {
    margin-left: 12px;
}

.child-margins-x-16 > * + * {
    margin-left: 16px;
}

.child-margins-x-20 > * + * {
    margin-left: 20px;
}

.child-margins-x-24 > * + * {
    margin-left: 24px;
}

.child-margins-x-32 > * + * {
    margin-left: 32px;
}

.child-margins-x-48 > * + * {
    margin-left: 48px;
}

.child-margins-x-64 > * + * {
    margin-left: 64px;
}

.child-margins-x-128 > * + * {
    margin-left: 128px;
}

.child-margins-y-2 > * + * {
    margin-top: 2px;
}

.child-margins-y-4 > * + * {
    margin-top: 4px;
}

.child-margins-y-8 > * + * {
    margin-top: 8px;
}

.child-margins-y-12 > * + * {
    margin-top: 12px;
}

.child-margins-y-16 > * + * {
    margin-top: 16px;
}

.child-margins-y-20 > * + * {
    margin-top: 20px;
}

.child-margins-y-24 > * + * {
    margin-top: 24px;
}

.child-margins-y-32 > * + * {
    margin-top: 32px;
}

.child-margins-y-48 > * + * {
    margin-top: 48px;
}

.child-margins-y-64 > * + * {
    margin-top: 64px;
}

.child-margins-x-128 > * + * {
    margin-top: 128px;
}

.child-margins-x-hairline > * + * {
    margin-left: 1px;
}

.child-margins-y-hairline > * + * {
    margin-top: 1px;
}

/
/
/
/
Paddings
.padding-hairline {
    padding: 1px;
}

.padding-4 {
    padding: 4px;
}

.padding-8 {
    padding: 8px;
}

.padding-12 {
    padding: 12px;
}

.padding-16 {
    padding: 16px;
}

.padding-20 {
    padding: 20px;
}

.padding-24 {
    padding: 24px;
}

.padding-32 {
    padding: 32px;
}

.padding-48 {
    padding: 48px;
}

.padding-64 {
    padding: 64px;
}

.padding-128 {
    padding: 128px;
}

.padding-top {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.padding-bottom {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
}

.padding-left {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.padding-x {
    padding-top: 0;
    padding-bottom: 0;
}

.padding-y {
    padding-left: 0;
    padding-right: 0;
}

.padding-top-0 {
    padding-top: 0;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-0 {
    padding: 0;
}

/
/
/
/
Margins
.margin-auto {
    margin: auto;
}

.margin-auto-x {
    margin-left: auto;
    margin-right: auto;
}

.margin-auto-y {
    margin-top: auto;
    margin-bottom: auto;
}

.margin-hairline {
    margin: 1px;
}

.margin-2 {
    margin: 2px;
}

.margin-4 {
    margin: 4px;
}

.margin-8 {
    margin: 8px;
}

.margin-12 {
    margin: 12px;
}

.margin-16 {
    margin: 16px;
}

.margin-20 {
    margin: 20px;
}

.margin-24 {
    margin: 24px;
}

.margin-28 {
    margin: 28px;
}

.margin-32 {
    margin: 32px;
}

.margin-48 {
    margin: 48px;
}

.margin-64 {
    margin: 64px;
}

.margin-128 {
    margin: 128px;
}

.negative-margin-2 {
    margin: -2px;
}

.negative-margin-4 {
    margin: -4px;
}

.negative-margin-8 {
    margin: -8px;
}

.negative-margin-12 {
    margin: -12px;
}

.negative-margin-16 {
    margin: -16px;
}

.negative-margin-20 {
    margin: -20px;
}

.negative-margin-24 {
    margin: -24px;
}

.negative-margin-32 {
    margin: -32px;
}

.negative-margin-48 {
    margin: -48px;
}

.negative-margin-64 {
    margin: -64px;
}

.negative-margin-128 {
    margin: -128px;
}

.margin-top {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.margin-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

.margin-left {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.margin-x {
    margin-top: 0;
    margin-bottom: 0;
}

.margin-y {
    margin-left: 0;
    margin-right: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-right-0 {
    margin-right: 0;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-left-0 {
    margin-left: 0;
}

.margin-0 {
    margin: 0;
}

.cut-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
}

/* Overriding tab menu styles */

.Component-root-2 {
    font-size: 0.5rem !important;
    line-height: 1.25 !important;
    font-weight: 600 !important;
    /* text-transform: uppercase !important; */
    letter-spacing: 0.1rem !important;
    font-family: "Roboto", sans-serif !important;
}

.line-height-1-2 {
  line-height: 1.2;
}

main.stream-layout {
  margin: 0 auto !important;
  width: 97%;
}

.streams-wrapper {
  background-color: hsl(198deg 20% 5%);
  padding: 1rem;
  border-radius: 8px;
}

@media only screen and (max-width: 1080px) {
  .streams-wrapper {
    flex-direction: column;
  }
  .streams-wrapper > div {
    width: 100%;
  }
  .streams-wrapper iframe {
    max-height: 36vh;
  }
}

.tab {
  cursor: pointer;
  position: relative;
  font-weight: 500;
  justify-content: center;
  display: flex;
  background: var(--matte-black);
  border-bottom: 3px solid var(--bright-blue);
}

.tab:first-of-type.tab-active,
.stream-tabs .tab.tab-active {
  background-color: var(--dark);
  border-bottom: 3px solid var(--dark);
}

.tab.tab-active {
  background: #2367a6;
  border-bottom: 3px solid #2367a6;
}


.pulse-dot {
  position: relative;
  left: -8px;
  top: 4px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: var(--bright-blue);
}

.pulse-dot:after {
  content:"";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: var(--bright-blue);
  display:block;
  animation: pulse 2s ease 0s infinite;
}

.pulse-dot:before {
    content:"";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #06D6A0;
    display:block;
    animation: pulse2 2s ease 0s infinite;
}

@keyframes pulse {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  80% {
      opacity: 0;
      transform: scale(2.5);
  }
  100% {
      opacity: 0;
      transform: scale(3);
  }
}

@keyframes pulse2 {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  30% {
      opacity: 1;
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(2.5);
  }
}
