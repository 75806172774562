.toast {
  background-color: #eff3f9 !important;
  border-radius: 4px !important;
  text-align: center;
  margin-top: -10px;
  height: 75px;
}

@media only screen and (max-width: 600px) {
  .toast {
    width: calc(100vw - 10px - 10px);

    margin-top: 10px;
    margin-left: 10px;

    margin-right: 10px;
  }
}
