.topLogo {
	left: 50%;
	transform: translate(-50%);
	position: absolute;
	top: 25px;
	height: 30px;
	font-family: var(--font-plex-sans);
	font-weight: 500;
}

.topBarContainer {
	background-color: var(--deepest-dark);
	position: absolute;
	z-index: 2;
	top: 0px;
	height: var(--top-bar-height);
	width: 100%;
	box-shadow: 0px 0px 9px 1px var(--deepest-dark);
}
@media only screen and (min-width: 1080px) {
	.topBarContainer {
		left: 0px;
		height: var(--top-bar-height-desktop);
	}
}
.topBarContainer.admin {
	background-color: white;
	box-shadow: none;
}
