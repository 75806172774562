@keyframes ldio-ovs4x40r7d {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.ldio-ovs4x40r7d div {
  position: absolute;
  width: 33%;
  margin-left: 75px;
  height: 100%;
  top: 0px;
  animation: ldio-ovs4x40r7d 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ldio-ovs4x40r7d div:nth-child(1) {
  transform: translate(23.115px, 0);
  background: #00343c;
  animation-delay: -0.6s;
  width: 33px;
}
.ldio-ovs4x40r7d div:nth-child(2) {
  transform: translate(63.31499999999999px, 0);
  background: #294f50;
  animation-delay: -0.4s;
  width: 33px;
}
.ldio-ovs4x40r7d div:nth-child(3) {
  transform: translate(103.51499999999999px, 0);
  background: #65f5e6;
  animation-delay: -0.2s;
  width: 33px;
}

.loadingio-spinner-bars-h089f3sylmg {
  width: 50%;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-ovs4x40r7d {
  width: 100%;
  height: 5px;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ovs4x40r7d div {
  box-sizing: content-box;
}
