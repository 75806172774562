.button {
  font-family: var(--font-plex-sans);
  border-radius: 4px;
  line-height: 20.8px;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
}

.button.green {
  background-color: var(--dark);
  color: white;
  border: 1px solid var(--bright-blue);
  box-sizing: border-box;
}

.button.greenDisabled {
  background-color: var(--dark);
  color: var(--light-gray);
  cursor: auto;
  border: 1px solid var(--gray);
  box-sizing: border-box;
}

.button.basic {
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  background-color: var(--blue);
  color: white;
}
.button-green {
}
