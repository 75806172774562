.nav-item {
  flex: 1;
}

.nav-icon {
  height: 20px;
  margin-bottom: 5px;
}
.nav-item-label {
  margin-top: var(--small-spacing);
  font-size: 1.1rem;
}

.nav-item-label-nomargin {
  font-size: 1.1rem;
  position: relative;
  bottom: 3px;
}

@media only screen and (min-width: 1080px) {
  .nav-icon {
    height: 27px;
    margin-bottom: 0px;
  }

  .nav-item {
    flex-direction: row;
    cursor: pointer;
    font-size: 1.5rem;
    flex: max-content;
  }

  .nav-item-label-nomargin {
    font-size: 1.5rem;
    position: relative;
    bottom: 0px;
    margin-left: 0px;
  }

  .nav-item-label {
    margin-top: 10px;
    margin-left: var(--medium-spacing);
    font-size: 1.5rem;
  }
}
